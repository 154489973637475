<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import _debounce from 'lodash.debounce';
import NewCampaignModel from "@/components/campaigns/new-campaign";
import Multiselect from "vue-multiselect";
import EmptyList from "@/components/widgets/empty_list"
import filterP from "@/components/campaigns/filter";
import Drawer from "vue-simple-drawer";

import {
	campaignMethods,
  userMethods,
  eventMethods,
  projectMethods
} from "@/state/helpers";


/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Campaigns",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    NewCampaignModel,
    Multiselect,
    EmptyList,
    filterP,
    Drawer
  },
  data() {
    return {
      title: "Events",
      items: [],
      showModal: false,
      isLoading: false,
      fullPage: true,
      canCancel: false,
      useSlot: false,
      color: "#007bff",
      bgColor: "#ffffff",
      height: 128,
      width: 128,
      currentPage:1,
			perPage:9,
      rows:0,
      campaigns:[],
      showModalCreateCampaign: false,
      campaign:{},
      projects:[],
      project: null,
      useOnlyActives: true,
      showCopyToProjectModal: false,
      auxProject: null,
      empty_list_config:{
            "title" : this.$t("campaigns.empty_search_title"),
            "linkButtonText" : this.$t("common.reset_search"),
            icon: "fa-cubes"
        },
        empty_config:{
            "title" : this.$t("campaigns.empty_title"),
            "subtitle" : this.$t("campaigns.empty_text"),
            "buttonText" : this.$t("campaigns.new_campaign"),
            icon: "fa-cubes"
        },
        query:'',
        openRightDrawer: false,
        filters: [], 
     }
  },
  
  mounted() {
      this.project = localStorage.getItem('current_project');
      this.loadCampaigns();
  },
  created(){
		this.debounceCampaigns = _debounce(this.searchCampaigns.bind(this), 1000);
	},
  methods: {
    ...campaignMethods,
    ...userMethods,
    ...eventMethods,
    ...projectMethods,

    getCustomFilterQuery(){
      let q = '';
      if(this.filters.length>0){
        this.filters.forEach((f)=>{
          if(f.key == 'status'){
            if(f.value == 'status_active'){
              q+= `, "endDate" : {"$gte" : "${this.formatDateQuery(new Date())}"}, "startDate" : {"$lt" : "${this.formatDateQuery(new Date())}"} `
            }else if (f.value == 'status_upcoming') {
              q+= `,"startDate":{"$gte" : "${this.formatDateQuery(new Date())}"}`
            }else if (f.value == 'status_completed') {
              q+= `,"endDate" : {"$lt" : "${this.formatDateQuery(new Date())}"}`
            }
          }else{
            q+= `, "${f.key}": "${f.value}"`
          }
          
        });
      }
      return q;
    },

    onResetSearchClicked(){
      this.query = '';
      this.loadCampaigns();
    },

    loadProjects(){
			
      let loader = this.$loading.show();
      this.isLoading = true;
      
			const params = {
				q : `limit=50`
			}

			this.getProjects(params).then((projects)=>{
        if(projects&& projects.data){
          this.projects= projects.data;
          this.projects = this.projects.filter(p=> p._id != localStorage.getItem('current_project'));
        }
      }).catch(()=>{
        
      }).finally(()=>{
        this.isLoading = false;
        loader.hide();
      });
    },

    loadGroupAndEventsDataByCampaign(){
    
      for (let i=0;i< this.campaigns.length;i++){
        let c = this.campaigns[i];
        
        const params={
          q: `where[group]=${c.group}`,
        }
        this.getUsers(params).then((res)=>{
          c.totalUsers = res.totalCount;
          this.$set(this.campaigns, i, c)
        });
        
        const params2={
          q: `where[campaign]=${c._id}`,
        }
        this.getEvents(params2).then((res)=>{
          c.totalEvents = res.totalCount;
          this.$set(this.campaigns, i, c)
        });
      }
    },


    onCopyCampaign(campaign){
      let newCampaign = Object.assign({}, campaign);

      newCampaign.items = [];
      newCampaign.name = `COPY - ${campaign.name}`;

      delete newCampaign._id;
      delete newCampaign.createdAt;
      delete newCampaign.updatedAt;

      let loader = this.$loading.show();

      this.createCampaign(newCampaign).then(()=>{
            this.$notify({ type: 'success', text: this.$t("campaigns.new_campaign_success"),title:  this.$t("campaigns.new_campaign") });

          this.showModalCreateCampaign = false;
          this.currentPage= 1;
          this.loadCampaigns();
      }).catch(()=>{
          this.$notify({ type: 'error', text: this.$t("campaigns.new_campaign_error"),title:  this.$t("campaigns.new_campaign") });
      }).finally(()=>{
          loader.hide();
        })

    },

    hideModal() {
      this.showModalCreateCampaign = false;
      this.campaign = {};
    },
    
    onNewCampaignClicked(){
      this.showModalCreateCampaign = true;
    },

    onConfirmCreateCampaign(){
      let loader = this.$loading.show();
        
        this.getCampaignDefaultSettings().then( (settings) => {
          this.campaign.products = [];
          this.campaign.durationHour = 1;
          this.campaign.durationMinutes = 0;
          this.campaign.settings = settings;

          this.createCampaign(this.campaign).then(()=>{
            this.$notify({ type: 'success', text: this.$t("campaigns.new_campaign_success"),title:  this.$t("campaigns.new_campaign") });

            this.showModalCreateCampaign = false;
            this.currentPage= 1;
            this.loadCampaigns();
        }).catch(()=>{
          this.$notify({ type: 'error', text: this.$t("campaigns.new_campaign_error"),title:  this.$t("campaigns.new_campaign") });
        }).finally(()=>{
          loader.hide();
        })
        })
    },

    loadCampaigns(){
      let loader = this.$loading.show();
      this.isLoading = true;

      let filter = '';
      if(this.filters.length > 0){
        filter = this.getCustomFilterQuery();
      }

      const params={
        q: `whereObj={"project" : "${this.project}"${filter}}&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`
      }

      this.getCampaigns(params).then((campaigns)=>{
        if(campaigns&& campaigns.data){
          this.campaigns= campaigns.data;
          this.rows= campaigns.totalCount

          this.loadGroupAndEventsDataByCampaign();
      }
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t("campaigns.get_campaigns_error"),title:  this.$t("campaigns.title") });
      }).finally(()=>{
        this.isLoading = false;
        loader.hide();
      });
    },
    
    formatDate(date) {
      var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

      if (month.length < 2) 
        month = '0' + month;
      if (day.length < 2) 
        day = '0' + day;
      
      return [day, month, year].join('-');
    },

    formatDateQuery(d) {
      
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();

      if (month.length < 2) 
        month = '0' + month;
      if (day.length < 2) 
        day = '0' + day;
      
        return [year, month, day].join('-');
    },


    onCampaignListPageClicked() {
				this.loadCampaigns();
		},
    
    onCampaignClicked(grid){
      this.$router.push(
            {
              path: `/campaign?id=${grid._id}`,
            }
          ).catch(() => {});
    },

    getStatus(campaign){
      const now = new Date();
      const start = new Date(campaign.startDate);
      const end = new Date(campaign.endDate)
      
      if(start > now){
        return 'status_upcoming';
      }else if (end < now){
        return 'status_completed';
      }
      return 'status_active';

    },
    
    searchCampaigns(query){
      
      let loader = this.$loading.show();
      this.isLoading = true;
      this.empty_list_config.subtitle =  this.$t("campaigns.empty_search_text").replaceAll('#', this.query);

      if(query){
        this.campaigns=[];
        this.currentPage = 1;

        let filter = '';
        if(this.filters.length > 0){
          filter = this.getCustomFilterQuery();
        }

				const params={
					q: `whereObj={"name": {"$regex": ".*${query}.*", "$options":"i"}${filter}}&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}` ,
				}

        if(this.project){
          params.q+= `&where[project]=${this.project}` 
        }

        this.getCampaigns(params).then((campaigns)=>{
          if(campaigns&& campaigns.data){
            this.campaigns= campaigns.data;
            this.rows= campaigns.totalCount

            this.loadGroupAndEventsDataByCampaign();
          }
          this.isLoading = false;
          loader.hide();
        });
      }else{
        this.isLoading = false;
        this.currentPage = 1;
        loader.hide();
        this.loadCampaigns();
      }
    },
    
    onUseOnlyActivesChanged(){
      this.currentPage = 1;
      this.loadCampaigns();
    },
    onCopyCampaignToAnotherProject(data){
      this.campaign = data;
      this.loadProjects();
      this.showCopyToProjectModal = true;
    },
    onConfirmCopyCampaignToAnotherProject(){
      if(this.auxProject){
        delete this.campaign._id;
        delete this.campaign.createdAt;
        delete this.campaign.createdBy;
        delete this.campaign.updatedAt;
        delete this.campaign.updatedBy;

        this.campaign.products = [];
        this.campaign.items = [];
        this.campaign.project = this.auxProject._id;
        
        const loader = this.$loading.show()
        this.createCampaign(this.campaign).then(()=>{
          this.$notify({ type: 'success', text: this.$t("campaigns.copy_to_project_project_success"),title:  this.$t("audiences.title") });
          this.auxProject = null;
          this.campaign = null;
          this.showCopyToProjectModal = false;
        }).catch(()=>{
          this.$notify({ type: 'error', text: this.$t("campaigns.copy_to_project_project_error"),title:  this.$t("audiences.title") });
        }).finally(()=>{
          loader.hide();
        })
      }else{
        this.showCopyToProjectModal = false;
      }
    },
    rightDrawer(){
      this.openRightDrawer = false;
    },
    removeAllFilter(){
      this.$refs.filterRef.removeAll();

      this.applyCustomFilter();
    },
    applyCustomFilter(){
      this.openRightDrawer = false;

      this.filters = this.$refs.filterRef.getAllFilters();
      this.currentPage = 1;
      this.searchCampaigns(this.query);
    },
    onFilterClicked(){
      this.openRightDrawer=true; 
      setTimeout(()=>{
        this.$refs.filterRef.setAllFilter(this.filters)
      },1000)
    },
    onRemoveFilter(filter){
      this.filters = this.filters.filter(f=> f.key != filter.key);

      this.currentPage = 1;
      this.searchCampaigns(this.query);
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('campaigns.title')" :items="items" />
      <div class="row">
        <div class="col">
          <div class="card">  
              <div class="card-body" :class="filters.length > 0 ? 'pb-2' : ''">
                  <div class="row mb-0">
                      <div class="col-sm-4 d-inline-flex">
                          <div class="search-box me-2 mb-0 d-inline-block">
                            <div class="position-relative">
                                <input
                                type="text"
                                class="form-control"
                                v-model="query"
                                :placeholder="$t('common.search')"
                                @input="debounceCampaigns($event.target.value)"
                                />
                                <i class="bx bx-search-alt search-icon"></i>
                            </div>
                          </div>
                          <ul class="nav nav-pills product-view-nav" v-on:click="onFilterClicked">
                            <li class="nav-item">
                              <a class="nav-link active" href="javascript: void(0);">
                                <i class="bx bx-filter align-middle"></i>
                              </a>
                            </li>
                          </ul>
                      </div>
                      <div class="col-sm-8">
                          <div class="text-sm-end">
                              <button
                                  type="button"
                                  class="btn btn-primary mb-0 me-0"
                                  @click="onNewCampaignClicked"
                                  v-if="campaigns.length>0 || query!=''">
                              <i class="mdi mdi-plus me-1"></i> {{$t('campaigns.new_campaign')}}
                          </button>
                          </div>
                      </div>
                  </div>
                  <div class="badge bg-primary m-1 mt-2 mb-0" v-for="f in filters" :key="f.key">
                    <div class="d-flex align-items-center justify-content-between">
                        <span class="pe-2">{{ f.badge }}</span>
                        <i class="fa fa-times interact " v-on:click="onRemoveFilter(f)"></i>
                    </div>
                  </div>
              </div>
              <EmptyList :config="empty_list_config" v-if="!isLoading && campaigns.length == 0 && query!=''" @onButtonClicked="onResetSearchClicked" class="mt-3 mb-3"/>
              <EmptyList :config="empty_config" v-if="!isLoading && campaigns.length == 0 && query ==''" @onButtonClicked="onNewCampaignClicked" class="mt-3 mb-3"/>
          </div>
        </div>
      </div>
      <div class="row">
          <div v-for="grid in campaigns" :key="grid.id" class="col-xl-4 col-sm-6" v-on:click="onCampaignClicked(grid)">
          <div class="card interact">
          <div class="card-body">
            <div class="top-right-menu">
                <b-dropdown
                    class="card-drop"
                    variant="white"
                    right
                    toggle-class="p-0"
                    menu-class="dropdown-menu-end">
                    <template v-slot:button-content>
                    <i class="mdi mdi-dots-vertical font-size-18"></i>
                    </template>

                    <b-dropdown-item @click.stop="onCopyCampaignToAnotherProject(grid)">
                        <i class="fas text-primary me-1 fas fa-share-square interact"></i>
                        {{$t('campaigns.copy_to_project')}}
                    </b-dropdown-item>
                    <b-dropdown-item @click.stop="onCopyCampaign(grid)">
                        <i class="fas fa-copy text-primary me-1 interact"></i>
                        {{$t('campaigns.duplicate')}}
                    </b-dropdown-item>
                  </b-dropdown>
            </div>
              <div class="d-flex">
                <div class="avatar-md me-4" v-if="grid.imageURL">
                    <span class="avatar-title rounded-circle bg-light text-danger font-size-16 profile-picture">
                      <img :src="`${grid.imageURL}`" alt class="img-thumbnail rounded-circle profile-picture" />
                    </span>

                    <li class="list-inline mt-4 mb-0">
                    <span class="badge font-size-10"
                    :class="{
                      'bg-success': `${getStatus(grid)}` === 'status_active',
                      'bg-warning': `${getStatus(grid)}` === 'status_upcoming',
                      'bg-primary': `${getStatus(grid)}` === 'status_completed',
                      'bg-danger': `${getStatus(grid)}` === 'status_inactive',
                  }"
                    >
                      {{$t(`campaigns.${getStatus(grid)}`)}}
                    </span>
                  </li>
                </div>
                <div class="avatar-md me-4 font-size-18 " v-if="!grid.imageURL">
                  <span class="avatar-title rounded-circle header-profile-user profile-picture">{{ grid.name ? grid.name.charAt(0) : ""}}</span>

                  <li class="list-inline mt-4 mb-0">
                    <span class="badge font-size-10"
                    :class="{
                      'bg-success': `${getStatus(grid)}` === 'status_active',
                      'bg-warning': `${getStatus(grid)}` === 'status_upcoming',
                      'bg-primary': `${getStatus(grid)}` === 'status_completed',
                      'bg-danger': `${getStatus(grid)}` === 'status_inactive',
                  }"
                    >
                      {{$t(`campaigns.${getStatus(grid)}`)}}
                    </span>
                  </li>
                </div>
                <div class="flex-grow-1 overflow-hidden">
                    <h5 class="text-truncate font-size-15">
                    <a href="javascript: void(0);" class="text-dark">{{
                        grid.name
                    }}</a>
                    </h5>
                    <p class="text-muted campaign-description">{{ grid.description }}</p>
                  </div>
                  
                </div>
            </div>
          <div class="px-4 py-3 border-top">
              <ul class="list-inline mb-0">
              <li class="list-inline-item me-3">
                  <span
                  class="badge"
                  :class="{
                      'bg-success': `${grid.status}` === 'Completed',
                      'bg-warning': `${grid.status}` === 'Pending',
                      'bg-danger': `${grid.status}` === 'Delay',
                  }"
                  >{{ grid.status  }} </span
                  >
              </li>
              <li
                  v-b-tooltip.hover.top
                  class="list-inline-item me-3"
                  :title="$t('campaigns.new_campaign_basicInfo_endDate')">

                  <i class="bx bx-calendar me-1"></i>
                  {{ formatDate(new Date(grid.endDate))}}
              </li>
              <li
                  v-b-tooltip.hover.top
                  class="list-inline-item me-3"
                  :title="$t('events.title')">
                  <i class="bx  bx-video me-1"></i>
                  {{ grid.totalEvents }}
              </li>

              <li
                  v-b-tooltip.hover.top
                  class="list-inline-item me-3"
                  :title="$t('campaigns.new_campaign_hosts')">
                  <i class="bx  bx-user me-1"></i>
                  {{ grid.totalUsers }}
              </li>

              <li
                  v-b-tooltip.hover.top
                  class="list-inline-item me-3"
                  :title="$t('campaigns.new_campaign_products')">
                  <i class="bx  bx-purchase-tag-alt me-1"></i>
                  {{grid.items.length}}
              </li>
              </ul>
          </div>
        </div>
        </div>
    </div>
    <div class="row mb-4" v-if="rows > perPage">
      <div class="col">
          <div class="dataTables_paginate paging_simple_numbers float-end">
              <ul class="pagination pagination-rounded mb-0">
                  <b-pagination
                      @input="onCampaignListPageClicked"
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                  ></b-pagination>
              </ul>
          </div>
      </div>
    </div>
    <b-modal
      v-model="showModalCreateCampaign"
      :title= "$t('campaigns.new_campaign')"
      title-class="text-black font-18"
      body-class="p-3"
      hide-footer>
      <NewCampaignModel :campaign="campaign" @closeCreateCampaignModal="hideModal" @createCampaign="onConfirmCreateCampaign"/>
    </b-modal>
    <b-modal
          :title="$t('personalizations.copy_to_project_title')"
          v-model="showCopyToProjectModal"
          @ok.prevent="onConfirmCopyCampaignToAnotherProject"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
          <div class="row">
            <div class="col">
                <label>{{$t('personalizations.project')}}*</label>
                <multiselect
                  :options="projects" 
                  v-model="auxProject"
                  track-by="_id" 
                  label="name"
                  selectLabel=""
                  deselectLabel=""
                  :placeholder= "$t('projects.select_placeholder')"
                  :allowEmpty="false"
                  :show-labels="false"
                  class="form control"
                />
            </div>
          </div>
          <div class="row">
            <div class="col mt-4 bg-info bg-gradient text-white p-3 ms-3 me-3">
              <i class="mdi mdi-alert-circle-outline"></i> <p class="small">{{ $t('campaigns.copy_to_project_info') }}</p>
            </div>
          </div>
    </b-modal>
    <Drawer
          @close="rightDrawer"
          :align="'right'"
          :closeable="true"
          :maskClosable="true"
          :zIndex="1002"
          class="h-100">
          <div v-if="openRightDrawer" class="h-100">
              <div class="offcanvas-header">
                  <h4 class="offcanvas-title" id="offcanvasExampleLabel">
                    <i class="bx bx-gear font-size-16 align-middle me-1"></i>
                    {{$t('filter.title')}}
                  </h4>
              </div>
              <hr class="mt-1" />
              <div class="offcanvas-body flex-grow-1 h-100">
                <div>
                  <filterP ref="filterRef"></filterP>
                </div>
                <div class="position-fixed bottom-0 end-0 p-3">
                  <button class="btn btn-secondary me-2" @click="removeAllFilter">{{$t('filter.clean')}}</button>
                  <button class="btn btn-primary me-2" @click="applyCustomFilter">{{$t('filter.apply')}}</button>
                </div>
              </div>
            </div>
        </Drawer>
  </Layout>
</template>

<style scoped>
  .profile-picture {
      height: 70px !important;
      width: 70px !important;
      padding: 0px !important;
  }

  .campaign-description {
    height: 70px !important;
  }

  .interact{
    cursor: pointer;
  }

  .top-right-menu {
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;
}
</style>