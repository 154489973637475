<script>

import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";

import {
	groupMethods,
	projectMethods,
} from "@/state/helpers";

/**
 * New Campaign  component
 */

export default {
	components: {
		Multiselect,
		DatePicker
	},
	data() {
		return {
			submitted:false,
			groups:[],
			projects:[],
			startDate:null,
			endDate: null
		};
	},
	validations: {
		campaign: {
			name: {
				required,
			},
			description: {
				required,
			},
			startDate: {
				required,
			},
			endDate: {
				required,
			},
			project:{
				required
			},
			group:{
				required
			},
		},
	},
	methods: {
		...groupMethods,
		...projectMethods,

		onCreateCampaignClicked(){
			this.submitted = true;
			this.$v.$touch();

			if (this.$v.$invalid) {
				return;
			}

			this.$emit('createCampaign');
		},

		loadGroups(){
			let loader = this.$loading.show();
			
			this.getGroups().then((groups)=>{
				if(groups&& groups.data){
					this.submitted= false;
					this.groups= groups.data;
				}	
			}).catch(()=>{
				this.$notify({ type: 'error', text: this.$t("groups.get_groups_error"),title:  this.$t("groups.title") });
			}).finally(()=>{
				loader.hide();
			});
		},

		loadProjects(){
			let loader = this.$loading.show();
			
			this.getProjects().then((projects)=>{
				if(projects&& projects.data){
				this.projects= projects.data;
			}
			}).catch(()=>{
				this.$notify({ type: 'error', text: this.$t("projects.get_projects_error"),title:  this.$t("projects.title") });
			}).finally(()=>{
				loader.hide();
			});
		},

		startDateChanged(){
			this.campaign.startDate= this.startDate;
			
		},
		endDateChanged(){
			this.campaign.endDate= this.endDate;
		}

	},
	mounted() {
		this.loadGroups();
		this.loadProjects();
	},
	props: {
		campaign : {
			type: Object
		}
	},
	emits: ['createCampaign', 'closeCreateCampaignModal']
};
</script>

<template>
	<div>
		<div class="row">
			<div class="col">
				<div class="mb-3">
					<label for="campaignName">{{$t('campaigns.new_campaign_basicInfo_name')}}*</label>
					<input
						id="campaignName"
						v-model="campaign.name"
						name="name"
						type="text"
						class="form-control"
						:class="{
							'is-invalid': submitted && $v.campaign.name.$error,
						}"
					/>
					<div
						v-if="submitted && !$v.campaign.name.required"
						class="invalid-feedback">
							{{$t('campaigns.new_campaign_basicInfo_name_required')}}
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<div class="mb-3">
					<label for="campaignDesc">{{$t('campaigns.new_campaign_basicInfo_description')}}*</label>
					<textarea
						id="campaignDesc"
						v-model="campaign.description"
						class="form-control"
						:class="{
						'is-invalid': submitted && $v.campaign.description.$error,}"
						rows="2"></textarea>
					<div v-if="submitted && !$v.campaign.description.required" class="invalid-feedback">
						{{$t('campaigns.new_campaign_basicInfo_description_required')}}
					</div>
				</div>
			</div>  
		</div>
		<div class="row">
			<div class="col-sm-6">
				<div class="mb-3">
					<label for="campaignStartDate">{{$t('campaigns.new_campaign_basicInfo_startDate')}}*</label>
					<date-picker
						id="campaignStartDate"
						v-model="startDate"
						name="campaignStartDate"
						:class="{
						'is-invalid': submitted && $v.campaign.startDate.$error,}"
						@input="startDateChanged"
					/>
					<div
						v-if="submitted && !$v.campaign.startDate.required"
						class="invalid-feedback">
						{{$t('campaigns.new_campaign_basicInfo_startDate_required')}}
					</div>
				</div>
			</div>
			<div class="col-sm-6">
				<div class="mb-3">
					<label for="campaignEndDate">{{$t('campaigns.new_campaign_basicInfo_endDate')}}*</label>
					<date-picker
						id="campaignEndDate"
						v-model="endDate"
						name="campaignEndDate"
						type="date"
						:class="{
						'is-invalid': submitted && $v.campaign.endDate.$error,}"
						@input="endDateChanged"
					/>
					<div v-if="submitted && !$v.campaign.endDate.required" class="invalid-feedback">
						{{$t('campaigns.new_campaign_basicInfo_endDate_required')}}
					</div>
				</div> 
			</div> 
		</div>
		<div class="row">
			<div class="col">
				<div class="mb-3">
					<label for="role">{{$t('campaigns.new_campaign_basicInfo_project')}}*</label>
					<multiselect 
						v-model="campaign.project"
						:options="projects"
						:selectLabel="$t('common.select')"
						:deselectLabel="$t('common.deselect')"
						:placeholder="$t('dashboard.project_placeholder')"
						:multiple="false"
						track-by="_id" 
						label="name"
						:showNoResults="false"
						:class="{ 'is-invalid': submitted && $v.campaign.project.$error}">
					</multiselect>
					<div
						v-if="submitted && !$v.campaign.project.required"
						class="invalid-feedback">
						{{$t('campaigns.new_campaign_basicInfo_project_required')}}
					</div>
				</div>
			</div>
		</div>   
		<div class="row">
			<div class="col">
				<div class="mb-3">
					<label for="role">{{$t('campaigns.new_campaign_basicInfo_group')}}*</label>
					<multiselect 
						v-model="campaign.group"
						:options="groups"
						:selectLabel="$t('common.select')"
						:deselectLabel="$t('common.deselect')"
						:placeholder="$t('users.group_placeholder')"
						:multiple="false"
						track-by="_id" 
						label="name"
						:showNoResults="false"
						:class="{ 'is-invalid': submitted && $v.campaign.group.$error}">
					</multiselect>
					<div v-if="submitted && !$v.campaign.group.required" class="invalid-feedback">
						{{$t('campaigns.new_campaign_basicInfo_group_required')}}
					</div>
				</div>
			</div>
		</div> 
		<div class="text-end pt-5 mt-3">
			<b-button variant="light" @click="$emit('closeCreateCampaignModal')">{{$t('common.cancel')}}</b-button>
			<b-button variant="primary" class="ms-1" @click="onCreateCampaignClicked">{{$t('common.confirm')}}</b-button>
		</div>
	</div>
</template>

<style scoped>

</style>