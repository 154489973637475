<script>
import Multiselect from "vue-multiselect";

import {
  groupMethods,
  availabilityMethods
} from "@/state/helpers";

export default {
  data() {
    return {
      status : [
        { id: 'status_active',label : this.$t(`campaigns.status_active`)},
        { id: 'status_upcoming',label : this.$t(`campaigns.status_upcoming`)},
        { id: 'status_completed',label : this.$t(`campaigns.status_completed`)}
      ],

      auxStatus: null,
      groups: [],
      auxGroup: null,
      availabilities: [],
      auxAvailability:null,
      filters:[]
    };
  },
  components:{Multiselect},
  props: {
    
  },
  mounted(){
    
  },
  computed: {
    
    
  },
  methods: {
    ...groupMethods,
    ...availabilityMethods,

    loadAvailabilities(){
      let loader = this.$loading.show();

			const params={
				q: `limit=100`,
			}

			this.getAvailabilities(params).then((availabilities)=>{
				if(availabilities&& availabilities.data){
					this.availabilities= availabilities.data;
          this.auxAvailability = this.filters.filter(f=> f.key== 'availability').length > 0 ? this.availabilities.filter(g=> g._id == this.filters.filter(f=> f.key== 'availability')[0].value)[0]  :null
			}
			}).catch(()=>{
				this.$notify({ type: 'error', text: this.$t("availability.get_availability_error"),title:  this.$t("availability.title") });
			}).finally(()=>{
				this.isLoading = false;
				loader.hide();
			});
    },
    loadGroups(){
			let loader = this.$loading.show();
			
			this.getGroups().then((groups)=>{
				if(groups&& groups.data){
					this.groups= groups.data;
          this.auxGroup = this.filters.filter(f=> f.key== 'group').length > 0 ? this.groups.filter(g=> g._id == this.filters.filter(f=> f.key== 'group')[0].value)[0]  :null
				}	
			}).catch(()=>{
				this.$notify({ type: 'error', text: this.$t("groups.get_groups_error"),title:  this.$t("groups.title") });
			}).finally(()=>{
				loader.hide();
			});
		},

    removeAll(){
      this.auxStatus = null;
      this.auxGroup = null;
      this.auxAvailability
    },

    getAllFilters(){
      const filters= [];
      
      if(this.auxStatus){
        filters.push({
          key: 'status',
          value: this.auxStatus.id,
          badge: `${this.$t('personalizations.status')}: ${this.auxStatus.label}`
        });
      }

      if(this.auxGroup){
        filters.push({
          key: 'group',
          value: this.auxGroup._id,
          badge: `${this.$t('campaigns.group')}: ${this.auxGroup.name}`
        });
      }

      if(this.auxAvailability){
        filters.push({
          key: 'availability',
          value: this.auxAvailability._id,
          badge: `${this.$t('campaigns.availability')}: ${this.auxAvailability.name}`
        });
      }
      
      return filters;
    },

    setAllFilter(filters){
      this.auxStatus = filters.filter(f=> f.key== 'status').length > 0 ? this.status.filter(g=> g.id == filters.filter(f=> f.key== 'status')[0].value)[0]  :null
      this.filters = filters;
      
      this.loadAvailabilities();
      this.loadGroups();
    }
  },
  watch:{
    
  }
};
</script>

<template>
  <div class="flex-grow-1 h-100 accordion" role="tablist">
    <div class="mb-2">
      <div class="badge bg-primary m-1" v-if="auxStatus">
          <div class="d-flex align-items-center justify-content-between">
              <span class="pe-2">{{$t('personalizations.status')}}: {{auxStatus?.label}}</span>
              <i v-on:click="auxStatus =null" class="fa fa-times interact"></i>
          </div>
      </div>
    </div>
    <div class="mb-2">
      <div v-b-toggle="'accordion-4'" class="group-header w-100 bg-light border border-1 border-start-0 border-end-0 d-flex align-items-center interact" style="border-color: #ced4da !important; height: 45px;" role="tab">
        <button   class="btn btn-link text-start text-decoration-none text-muted fw-bold me-auto">{{$t(`campaigns.status`)}}</button>
        <i class="ml-auto text-end bx bx-chevron-down interact me-1 font-size-18" ></i>
      </div>
        <b-collapse :id="`accordion-4`" role="tabpanel" class="mt-2">
          <p class="small mb-1">{{$t('campaigns.status_subtitle')}}</p>
          <multiselect 
            :options="status"
            :selectLabel="$t('common.select')"
            :deselectLabel="$t('common.deselect')"
            track-by="id" 
            label="label"
            :show-labels="false"
            :multiple="false"
            v-model="auxStatus"
            :allow-empty="false">
          </multiselect>
        </b-collapse>
    </div>
    <div class="mb-2">
        <div v-b-toggle="'accordion-1'" class="group-header w-100 bg-light border border-1 border-start-0 border-end-0 d-flex align-items-center interact" style="border-color: #ced4da !important; height: 45px;" role="tab">
          <button   class="btn btn-link text-start text-decoration-none text-muted fw-bold me-auto">{{$t('campaigns.group')}}</button>
          <i class="ml-auto text-end bx bx-chevron-down interact me-1 font-size-18" ></i>
        </div>
          <b-collapse :id="`accordion-1`" role="tabpanel" class="mt-2">
            <p class="small mb-1">{{ $t('campaigns.group_subtitle') }}</p>
            <multiselect 
              :options="groups"
              :selectLabel="$t('common.select')"
              :deselectLabel="$t('common.deselect')"
              track-by="_id" 
              label="name"
              :show-labels="false"
              :multiple="false"
              v-model="auxGroup"
              :allow-empty="false">
            </multiselect>
          </b-collapse>
    </div>
    <div class="mb-2">
        <div v-b-toggle="'accordion-2'" class="group-header w-100 bg-light border border-1 border-start-0 border-end-0 d-flex align-items-center interact" style="border-color: #ced4da !important; height: 45px;" role="tab">
          <button   class="btn btn-link text-start text-decoration-none text-muted fw-bold me-auto">{{$t('campaigns.availability')}}</button>
          <i class="ml-auto text-end bx bx-chevron-down interact me-1 font-size-18" ></i>
        </div>
          <b-collapse :id="`accordion-2`" role="tabpanel" class="mt-2">
            <p class="small mb-1">{{ $t('campaigns.availability_subtitle') }}</p>
            <multiselect 
              :options="availabilities"
              :selectLabel="$t('common.select')"
              :deselectLabel="$t('common.deselect')"
              track-by="_id" 
              label="name"
              :show-labels="false"
              :multiple="false"
              v-model="auxAvailability"
              :allow-empty="false">
            </multiselect>
          </b-collapse>
    </div>
  </div>
</template>
